<template>
  <div>
    <Modal @close="cerrar">
      <div class="title">Gasto nuevo</div>
      <form @submit.prevent="generar_gasto">
        <div class="body">
          <div class="row form-group">
            <label for="gasto.gasto" class="col-form-label col-sm-2">Concepto</label>
            <div class="col-sm-10"><input v-model="gasto.gasto" type="text" name="gasto.gasto" id="gasto.gasto" class="form-control" autocomplete="off"></div>
          </div>
          <div class="row form-group">
            <label for="gasto.autorizador_id" class="col-form-label col-sm-2">Área</label>
            <div class="col-sm-10">
              <select v-model="gasto.autorizador_id" name="gasto.autorizador_id" id="gasto.autorizador_id" class="form-control">
                <option v-for="autorizador in autorizadores" :key="autorizador.id" :value="autorizador.id">{{ autorizador.area }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="gasto.monto" class="col-form-label col-sm-2">Monto</label>
            <div class="col-sm-10">
              <IC v-model="gasto.monto" label="$" id="gasto.monto" name="gasto.monto" :formats="['moneyFormat']"/>
            </div>
          </div>
          <div class="row form-group">
            <label for="gasto.info_pago" class="col-form-label col-sm-2">Información de pago</label>
            <div v-if="info_pagos.length > 0" class="col-sm-8">
              <select v-model="gasto.info_pago" name="gasto.info_pago" id="gasto.info_pago" class="form-control">
                <option v-for="info in info_pagos" :key="info.id" :value="info.id">{{ info.nombre }}</option>
              </select>
            </div>
            <div v-else class="col-sm-8 my-auto">No hay información de pago</div>
            <div class="col-sm-2"><button class="btn secondary-btn" type="button" @click="nuevo_info_pago=true">Nuevo</button></div>
          </div>
          <div class="row form-group">
            <label for="gasto.observacion" class="col-form-label col-sm-2">Observacion</label>
            <div class="col-sm-10"><textarea v-model="gasto.descripcion" type="text" name="gasto.observacion" id="gasto.observacion" class="form-control descripcion" placeholder="Agregar observación (opcional)..."></textarea></div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Documentos</label>
            <div class="col-sm-9 file_list_container">
              <div class="row header">
                <div class="col-sm-9 my-auto">Archivo</div>
                <div class="col-sm-3 text-center">Opciones</div>
              </div>
              <template v-if="gasto.archivos.length > 0">
              <div v-for="(archivo,index) in gasto.archivos" :key="index" class="row form-group file_list">
                <div class="col-sm-9 my-auto">{{ archivo.name }}</div>
                <div class="col-sm-3 text-center"><button class="btn btn-secondary" type="button" @click="eliminar_archivo(index)">Eliminar</button></div>
              </div>
              </template>
              <div v-else class="row form-group">
                <div class="col-sm-12">No hay documentos</div>
              </div>
            </div>
          </div>
          <div class="row form-group">
            <div class="col-sm-3 offset-sm-9">
              <button class="btn complementary-btn" type="button" @click="seleccionar_archivo">Agregar archivo (opcional)</button>
              <input class="file_hide" type="file" name="select_file" id="select_file" ref="select_file" @change="agregar_archivo">
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">Generar gasto</button></div>
            <div class="col-sm-2"><button class="btn danger-btn" type="button" @click="cerrar">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>

    <InfoPago v-if="nuevo_info_pago" @close="info_pago_close" @update="info_pago_update"/>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import IC from '@/components/Form/InputCompuesto'
  import InfoPago from '@/apps/tesoreria/pages/Tesoreria/InfoPago'

  import api from '@/apps/tesoreria/api/gastos'
  import infopagos from '@/apps/tesoreria/api/infopagos'

  export default {
    components: {
      Modal, IC, InfoPago
    }
    ,props: {
      autorizadores: {
        type: Array
        ,default: function() {
          return []
        }
      }
    }
    ,data: function() {
      return {
        gasto: {
          gasto: null
          ,autorizador_id: null
          ,info_pago: null
          ,monto: null
          ,archivos: []
        }
        ,catalogo: []
        ,info_pagos: []
        ,nuevo_info_pago: false
      }
    }
    ,mounted: function() {
      this.gasto.autorizador_id = this.autorizadores[0].id;
      this.obtener_info_pagos();
    }
    ,methods: {
      cerrar: function() {
        this.$emit('close');
      }
      ,seleccionar_archivo: function() {
        this.$refs.select_file.click();
      }
      ,agregar_archivo: function() {
        if (this.$refs.select_file.files.length > 0) {
          let archivo = this.$refs.select_file.files[0];
          this.$log.info('archivo',archivo);

          this.gasto.archivos.push(archivo);
        }
      }
      ,eliminar_archivo: function(index) {
        let archivos = [];

        for(let i=0; i<this.gasto.archivos.length; i++) {
          if (i != index)
            archivos.push(this.gasto.archivos[i]);
        }

        this.gasto.archivos = archivos;
      }
      ,generar_gasto: async function() {
        try {
          let form = new FormData();
          if (this.gasto.gasto)
            form.append('gasto',this.gasto.gasto);

          if (this.gasto.autorizador_id)
            form.append('autorizador_id',this.gasto.autorizador_id);

          if (this.gasto.info_pago)
            form.append('info_pago_id',this.gasto.info_pago);

          if (this.gasto.monto)
            form.append('monto',this.gasto.monto);

          if (this.gasto.descripcion)
            form.append('descripcion',this.gasto.descripcion);

          if (this.gasto.archivos.length > 0) {
            this.gasto.archivos.forEach(archivo => {
              form.append('archivos[]',archivo);
            });
          }

          let res = (await api.generar_gastos(form)).data;
          this.$log.info('res',res);

          this.$emit('close');
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_info_pagos: async function() {
        try {
          this.info_pagos = (await infopagos.obtener_informacion()).data;
          if (this.info_pagos.length > 0)
            this.gasto.info_pago = this.info_pagos[0].id;
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,info_pago_close: function() {
        this.nuevo_info_pago = false;
      }
      ,info_pago_update: function() {
        this.nuevo_info_pago = false;
        this.obtener_info_pagos();
        this.gasto.info_pago = this.info_pagos[this.info_pagos.length-1].id;
      }
    }
  }
</script>

<style lang="scss" scoped>
  .descripcion {
    height: 120px !important;
  }

  .file_hide {
    display: none;
  }

  .file_list_container {
    max-height: 220px;
    // border: solid 1px #E6E6E6;
    padding: 3px 15px;
    overflow: auto;

    .file_list {
      button {
        margin: 3px;
      }
    }

    .file_list:nth-child(2n+1) {
      background-color: #E6E6E6;
    }
  }

  .header {
    background-color: #4D4D4D;
    color: #fff;
    padding: 5px 3px;
  }
</style>