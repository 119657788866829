<template>
  <div>
    <Modal :options="{width: '50vw', type: 'secondary'}" @close="cerrar">
      <div class="title">Agregar información de pago</div>
      <div class="body">
        <div class="row form-group">
          <label for="info.nombre" class="col-form-label col-sm-3">Nombre</label>
          <div class="col-sm-9"><input v-model="infopago.nombre" type="text" name="info.nombre" id="info.nombre" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="info.nombre_beneficiario" class="col-form-label col-sm-3">Nombre beneficiario</label>
          <div class="col-sm-9"><input v-model="infopago.nombre_beneficiario" type="text" name="info.nombre_beneficiario" id="info.nombre_beneficiario" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="info.insitucion_operante" class="col-form-label col-sm-3">Institución operante</label>
          <div class="col-sm-9">
            <select v-model="infopago.institucion_operante" name="info.insitucion_operante" id="info.insitucion_operante" class="form-control">
              <option v-for="opcion in catalogo.valores" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="info.tipo_cuenta" class="col-form-label col-sm-3">Tipo cuenta</label>
          <div class="col-sm-9">
            <select v-model="infopago.tipo_cuenta_beneficiario" name="info.tipo_cuenta" id="info.tipo_cuenta" class="form-control">
              <option v-for="cat_tipo in catalogo_tipo.valores" :key="cat_tipo.id" :value="cat_tipo.valor">{{ cat_tipo.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="info.cuenta_beneficiario" class="col-form-label col-sm-3">Cuenta beneficiario</label>
          <div class="col-sm-9"><input v-model="infopago.cuenta_beneficiario" type="text" name="info.cuenta_beneficiario" id="info.cuenta_beneficiario" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="info.rfc_curp_beneficiario" class="col-form-label col-sm-3">RFC / Curp</label>
          <div class="col-sm-9"><input v-model="infopago.rfc_curp_beneficiario" type="text" name="info.rfc_curp_beneficiario" id="info.rfc_curp_beneficiario" class="form-control"></div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="crear_infopago">Agregar</button></div>
          <div class="col-sm-2"><button class="btn danger-btn" @click="cerrar">Cancelar</button></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import catalogos from '@/apps/tesoreria/api/catalogos'
  import api from '@/apps/tesoreria/api/infopagos'

  export default {
    components: {
      Modal
    }
    ,data: function() {
      return {
        catalogo: {
          valores: []
        }
        ,catalogo_tipo: {
          valores: []
        }
        ,infopago: {
          nombre: null
          ,cuenta_beneficiario: null
          ,tipo_cuenta_beneficiario: null
          ,nombre_beneficiario: null
          ,institucion_operante: null
          ,rfc_curp_beneficiario: null
        }
      }
    }
    ,mounted: function() {
      this.obtener_catalogo();
    }
    ,methods: {
      obtener_catalogo: async function() {
        try {
          this.catalogo = (await catalogos.buscar_catalogo('bancos')).data;
          this.infopago.institucion_operante = this.catalogo.valores[0].valor;

          this.catalogo_tipo = (await catalogos.buscar_catalogo('tipos_cuentas')).data;
          this.infopago.tipo_cuenta_beneficiario = this.catalogo_tipo.valores[0].valor
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar: function() {
        this.$emit('close');
      }
      ,crear_infopago: async function() {
        try {
          let res = (await api.crear_informacion(this.infopago)).data;
          this.$log.info('res', res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss">
  
</style>