import Vue from 'vue'
import axios from '@/plugins/axios'
import store from '@/store'
import utils from '@/helpers/utilidades'

const baseURL = process.env.VUE_APP_API_TESORERIA;

class InfoPagos
{
  obtener_informacion() {
    return axios.get(`${baseURL}/infopagos`);
  }

  crear_informacion(payload) {
    return axios.post(`${baseURL}/infopagos`, payload);
  }
}

export default new InfoPagos()